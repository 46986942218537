.input {
  padding: 0 16px;
  height: 40px;
  margin: 4px 0;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 8px;
  min-width: 240px;

  &:focus {
    border: 1px solid #eb0028;
  }
}
.button {
  padding: 0 16px;
  border: none;
  font-weight: bold;
  height: 36px;
  border-radius: 8px;
  margin:0 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
}

.c70b44d {
  color: white;
  background-color: #70b44d;
  border: none;
  &:hover {
    background-color: #4d9429;
  }
}

.ceb0028 {
  color: white;
  background-color: #eb0028;
  &:hover {
    background-color: #ca0022;
  }
}

.cffffff {
  background-color: white;
  color: #828282;
  &:hover {
    color: #eb0028;
    background-color: #f7f7f7;
  }
}

.spinnerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.5);
  z-index: 99999;
  padding: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.Footer {
  color: rgb(113, 111, 111);
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
  padding-bottom: 10px;
  font-size: 18px;
  text-transform: capitalize;
}
.print_button{
  :hover{
    background: red;
  }
border: 1px solid red;
display: block;
  padding:10px;
  background-image: url("../../public/assets/print_icons.svg");
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.custom_input{
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  // background-color: #828282;
  padding: 5px;
  width: 30% !important;
  font-size: 14px !important;
}
.accept {
  color: #FFF;
  background: #44CC44;
  padding: 1px 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
}
.accept:hover {
  background: #6FE76F;
}
.deny {
  margin-left: 10px;
  color: #FFF;
  background: rgb(249, 45, 9);
  border-radius: 5px;
  padding: 1px 5px;
  text-align: center;
  font-size: 16px;

  border-radius: 5px; 
}
.deny:hover {
  background: rgb(255, 147, 128);
}
.loading_screen {
  direction: rtl;
  text-align: left;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 3vw;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: 1.4px;
  justify-content: center;
  text-align: center;
  .tamata_title {
    font-size: 30px;
    span {
      color: black;
    }
  }
}