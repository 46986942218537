.toggle_container {
    text-align: center;
    font-size: 12px;
    }
    .toggle-switch {
    position: relative;
    width:90px;
    display: inline-block;
    text-align: left;
    top: 5px;
    }
    .checkbox {
    display: none;
    }
    .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 10px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    /* height: 36px; */
    padding: 0;
    /* line-height: 36px; */
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "Enable";
    padding-left: 10px;
    height: 25px;

    background-color: #70B44D;
    color: #fff;
    letter-spacing: 1.3px;
    }
    .inner:after {
    content: "Disable";
    padding-right:6px;
    height: 25px;
    background-color: #bbbd;
    color: #fff;
    text-align: right;
    letter-spacing: 1.3px;

    }
    .switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 65px;
    border: 0 solid #bbb;
    border-radius: 22px;
    transition: all 0.3s ease-in 0s;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
    right: 0px;
    }
    