.Main {
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
height: 100vh;
  // background-color: #fbfbfb;
  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 400px;
      cursor: pointer;
      :hover{
        opacity: 0.9;
      }
      span {
        font-size: 24px;
        color: #333333;
        font-weight: 600;
        padding-left: 10px;
      }
      

      img {
        width: 200px;
      }
    }
    div {
      display: flex;
      align-items: center;
      font-size: 18px;
      p {
        border-bottom: 2px solid red;
        color: #333333;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
}
.Logout {
  background-color: #70b44d;
  color: white;
  padding: 0 16px;
  border: none;
  font-weight: bold;
  height: 40px;
  border-radius: 8px;
  margin: 8px;
  margin-left: 30px;
  cursor: pointer;

}
.search_container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .Search_box {
    border: 2px solid #87be58;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 500px;
    height: 50px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    img {
      width: 100%;
      height: 80%;
      flex: 1;
      border: none;
      fill: red;
      border-left: 1px solid #eee;
    }
    .input_search {
      border: none;
      height: 100%;
      // width: 80%;
      flex: 6;
      font-size: 18px;
      padding: 10px;
    }
  }
}
.ship_table {
  padding: 20px 40px;
  margin-top: 50px;
  height: 70vh;
  .table_content {
    width: 100%;
    padding: 10px 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  .ship_head {
    tr {
      border-bottom: 1px solid rgb(223, 223, 223);
      text-align: center;
      color: rgb(168,158,156);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      td {
        padding: 10px;
      }
    }
  }
  .ship_body {
    text-align: center;
    :hover{
      opacity: 0.8;
    }
    tr {
      border: 1px solid #eee;
      font-size: 18px;
      color: rgb(41, 41, 41);
      margin: 565656px;
      font-weight: 600;

      td {
        padding: 20px 10px;
      }
    }
  }
}
.orderID{
  color: #2296ff !important;
  font-weight: 700;
  span{

    border-bottom: 1px solid ;
    width: fit-content;
  }
}
.ship{
color: #70B44D !important;
font-size: 18px;
cursor: pointer;
:hover{
  opacity: 0.9;
}
}
.shipped_button{
  padding: 8px 15px;
  font-size: 16px;
  background-color: #70b44d;
  border: none;
  border-radius: 5px;
  color: white;
font-weight: 600;
letter-spacing: 1px;
cursor: pointer;
:hover{
  opacity: 0.8;
}
}