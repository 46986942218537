@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Cairo", sans-serif !important;
  box-sizing: border-box;
}
progress {
  height: 22px;
}
progress::-webkit-progress-bar {
  background-color: rgb(177, 177, 152);
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: red;
  border-radius: 7px;
}
progress::-moz-progress-bar {
  /* style rules */
}
#Invoice_print {
  display: none;
}
@media print {
  #Invoice_print {
    display: block;
  }
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}

/* Handle on hover */

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  &:focus,
  &:hover {
    outline: none;
  }
}
.main_wrapper{
  width: 90%;
  margin: 0 auto;
}
.login-container {
  // border: 1px solid black;
  // padding: 32px;
  width: 500px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  align-items: center;
  height: 100vh;
  .logo {
    margin-top: 128px;
  }
}
.login-left-side {
  width: 100%;
  .login-headding {
    margin: 0;
    margin-bottom: 8px;
  }
  .login-text {
    margin: 0;
    margin-bottom: 24px;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .input {
    width: 100%;
  }
  .button {
    margin-top: 32px;
    width: 30%;
  }
  .logo {
    @media only screen and (max-height: 520px) {
      visibility: hidden;
    }
  }
}
.container {
  // min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  // height: 100vh;
  overflow: hidden;
  margin: auto;
}

.logo-search-wrapper {
  display: flex;
  align-items: center;

  .logo {
    padding: 16px;
  }
}
.Modal_header {
  display: flex;
  justify-content: space-between;
}
.Exmple_download {
  color: blue;
  border-bottom: 1px solid blue;
  width: fit-content;
  cursor: pointer;
}
.table_container {
  overflow-x: hidden;
  margin: auto;
  width: 90%;
  height: 79vh;
  .thead {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #b0b0b0;
    margin-top: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;

    .small_width {
      width: 10% !important;
    }
    span {
      width: 100%;
      text-align: center;

    }
  }
  .odd_row {
    background: #f0f0f0;
  }
  .row_odd {
    height: 90% !important;
    overflow-y: auto;

    div:nth-child(even) {
      background: #f0f0f0;
    }
  }
  .row_even {
    height: 85% !important;

    div:nth-child(even) {
      background: #f0f0f0;
    }
  }
  .body_container {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    color: #505050;
    font-style: normal;
    font-weight: normal;
    font-size: 11px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 10px;
    text-align: left;
    cursor: pointer;
    
    span {
      width: 100%;
      text-align: center;

    
    }
    .small_width {
      width: 10% !important;
    }

    .select_width {
      width: 130px;
    }
  }
}
  .body_wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    color: #505050;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px 10px;
    text-align: left;
    cursor: pointer;

    .small_width {
      width: 30% !important;
    }
    span {
      width: 100%;
      margin: 0px 2px;
    }

    .select_width {
      width: 130px;
    }
  }
.show{
  display: block;
}
.hidden{
  display: none;
}
.navbar-wrapper {
  margin: 32px auto;
  margin-bottom: 16px;
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  img {
    cursor: pointer;
  }
  .navbar-left {
    display: flex;
    align-items: center;
  }
}
.body-continer {
  padding: 5rem;
  overflow: auto;
  margin: auto;
  display: flex;
}
.id-orders {
  margin-top: -80px !important;
}
.user_detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 10px;
  background: #f4f4f4;
  border-radius: 8px;
  width: 250px;
}
.info-block {
  padding: 2px 0;
}
.text-sm {
  color: #a3a3a3;
  font-size: 12px;
  font-family: "Cairo", sans-serif;
}
.text-md {
  color: #eb0028;
  font-size: 14px;
  line-height: 22px;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}
.text-lg {
  color: #eb0028;
  font-size: 22px;
  line-height: 22px;
  font-family: "Cairo", sans-serif;
  font-weight: 800;
}
.text-md-info {
  font-size: 16px;
  font-weight: 500;
  color: #505050;
  margin-top: -4px;
}
.status_orders {
  margin-left: 20px;
  padding-top: 0px;
  width: 100%;
  height: 80vh;
  overflow: auto;

  .ordesr_wrapper {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 7px;
  }
  .ordesr_wrapper span {
    margin-right: 10px;
    padding-bottom: 7px;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
  }
}
.orders {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .order_card {
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    padding: 12px 12px 12px 10px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    height: 50%;
    cursor: pointer;
  }
  .order_card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .order_card div span {
    color: #a3a3a3;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
  }
  .order_card div p {
    color: #514f4f;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
  }
  .order_card div:first-child {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
  }
  .order_card div:first-child p {
    color: red;
    font-size: 20px;
    font-weight: bold;
  }
}
.scroll {
  overflow-y: scroll;
  border-bottom: 1px solid #f0f0f0;
  margin: auto;
}
.order-pending {
  background-color: #ffdce2;
  color: #eb0028 !important;
  padding: 8px !important;
  text-align: center;
  font-size: 15px !important;
  border-radius: 8px;
}
.order-complete {
  background-color: #e0ffd0;
  color: #72b74d !important;
  padding: 8px !important;
  text-align: center;
  font-size: 15px !important;
  border-radius: 8px;
}
.New_order {
  .Modal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Modal_header div {
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    color: #a3a3a3;
  }
  .Modal_content {
    margin: 10px 0;
    overflow: hidden;
  }
  .Modal_content div {
    margin: 4px 0;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .Modal_content div span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    margin-right: 10px;
    display: inline-block;
    width: 140px;
    color: #505050;
  }
}
.open_order {
  padding: 10px;
  background: #eb0028;
  border-radius: 8px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
}
.open_button {
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.order_modal_info {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  div {
    display: flex;
    flex-direction: column;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #a3a3a3;
  }
  div p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #505050;
  }
}
.order_table {
  width: 100%;

  .m-table {
    width: 100%;
    display: block;
    thead {
      display: block;
      width: 100%;
      padding: 5px 0;
      tr {
        display: flex;
        justify-content: space-around;
        font-size: 16px;
        line-height: 30px;
        font-weight: normal;
        cursor: pointer;
        text-align: left;
      }
    }
    tbody {
      display: block;
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
      padding: 5px 0;

      tr {
        display: flex;
        border: 1px solid #e5e5e5;
        text-align: left;
        border-radius: 2px;
        margin-top: 20px;
        text-align: left;
        justify-content: space-around;
        align-items: center;
        padding: 5px 0;

        // cursor: pointer;

        td {
          font-family: Cairo;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 30px;
          /* identical to box height */

          color: #505050;
        }
      }
      tr:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
.payment-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.status {
  color: #72b74d;
  background: #e1f4d6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  text-transform: uppercase;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
.status-ontime {
  color: #72b74d !important;
  background: #e1f4d6;
  // width: 20px !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 12px;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.status-late {
  color: #eb0028 !important;
  background: #f0d2d7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 14px !important;

}
.status-upcoming {
  color: #ff9e1b !important;
  background: #ffe8c8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.Modal_header {
  display: flex;
  justify-content: space-between;
  .order_info {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
  }
  .order_info span {
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #a3a3a3;
  }
  .order_id {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 37px;

    color: #eb0028;
  }
}
.total_price_bottom {
  display: flex;
  justify-content: space-around;
  span {
    color: #eb0028;
    font-weight: 700;
    display: inline-block;

    // border-bottom: 2px solid #70b44d;
  }
}
.view_button {
  color: #f74260;
  font-weight: 600;
  letter-spacing: 1px;
  width: fit-content;

  font-size: 0.9em !important;
}
.view_button:hover {
  color: #e64a61;
}
.Denied_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 99vh;
}
.Denied_page div {
  text-align: center;
  font-size: 2em;
}
@media print {
}

.order_information {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order_title {
  display: flex;
  justify-content: space-between;
}
.open_upload {
  background-color: #4285f4;
  padding: 10px 18px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.capture_image {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}
.on-time {
  background: #e1f4d6;
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  color: #72b74d;
  margin: 10px 0;
}
.on-late {
  background: #e1f4d6;
  padding: 0px 10px;
  border-radius: 10px;
  line-height: 30px;
  background: #f0d2d7;
  margin: 10px 0;
  color: #eb0028;
}
.hover_effect:hover {
  opacity: 0.8 !important;
}
.hover_effect {
  width: 100% !important;
  font-size: 14px !important;
}
.full_hw {
  width: 100% !important;
  height: 100%;
}
.delivery {
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-weight: bold;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
  background-color: #ff1d43;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #fc7088;
  }
}

.dropbtn {
  padding: 0 16px;
  border: none;
  font-weight: bold;
  height: 40px;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }

  background-color: #70b44d;
  color: white;
  padding: 0 16px;
  font-size: 16px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  // position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  // border-radius: 20px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content div {
  cursor: pointer;
  color: #505050;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.wrapper_block {
  font-size: 18px !important;
  div {
    margin-right: 10px !important;
    color: red !important;
  }
}
.nav_list{
  margin: 0px  20px 2px 20px;
  span{
    color: #838383;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    opacity: 0.8;
    cursor: pointer;
    margin-left: 20px;
    line-height: 20px;
  
  }
  .active{
    border-bottom:2px solid #f85b76;

  } 
   span:hover{
    opacity: 0.5;

  }
}
.collect-pay{
  padding: 0 16px;
  border: none;
  font-weight: bold;
  height: 25px;
  border-radius: 8px;
  cursor: pointer;
}
.default{
  padding: 5px 10px;
  font-size: 12px;
cursor: pointer;
border: none;
border-radius: 20px;}
.test{
  padding: 20px;
  bottom: 16px;
  position: absolute;
  z-index: 1000;
  right: 16px;
  margin: 10px;
}

.fab-container {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column-reverse;
  position: fixed;
  right: 2em;
  bottom: 2em;
  max-height: 152px;

  &.open {
    max-height: max-content;
  }

  li {
    border-radius: 50%;
    box-shadow: 0 3px 6px lightgrey;
    display: grid;
    place-items: center;
    margin: 8px 0;
    font-size: 28px;
    padding: 12px;
    cursor: pointer;
    position: relative;
  }

  .fab-button {
    background-color: #00a8ff;

    svg {
      fill: white;
    }
  }

  .fab-action {
    transform: translateY(50px) scale(0);
    transition: transform 300ms, opacity 300ms;
    opacity: 0;

    &:hover {
      .tooltip {
        transform: translateX(-100%) scale(1);
        opacity: 1;
      }
    }

    &.open {
      transform: translateY(0) scale(1);
      opacity: 1;
    }

    .tooltip {
      padding: 4px 6px;
      font-size: 12px;
      position: absolute;
      left: -12px;
      transform: translateX(-75%);
      background-color: #353b48;
      border-radius: 4px;
      color: white;
      opacity: 0;
      transition: transform 300ms, opacity 300ms;
      user-select: none;
    }
  }
}
.body_style{

  font-size: 12px !important;
}
.receivedBtn{



  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 10px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }

}
.popUp{
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}